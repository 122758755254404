import React from "react"
import { Link, graphql, useStaticQuery } from "gatsby"
import PageLayout from "../components/new-pagelayout"
import { StaticImage } from "gatsby-plugin-image"
//import * as blogStyles from "./blog.module.scss"
//const blogStyles = { post: "", posts: "" }

const BlogPostCard = ({ title, date, author, excerpt, slug, cover }) => {
  //const imageIndex = title.includes("Open") ? 1 : 0
  /*const staticImgSrc = [
    "../posts/dronartavling.jpeg",
    "../posts/picnic.jpeg",
  ][1]*/
  //const staticImgSrc = //title?.includes("Open")
  //?
  //"../posts/dronartavling.jpeg"
  //: "../posts/picnic.jpeg"
  //const imgSrc = `../${cover}` || staticImgSrc

  //const image = getImage(staticImgSrc)
  //console.log({
  //cover,
  //image,
  //title,
  //imageIndex,
  //date,
  //author,
  //slug,
  //key,
  //cover,
  //staticImgSrc,
  //})
  return (
    <div class="w-full md:w-1/2 px-4 mb-16">
      <Link to={`/blog/${slug}`}>
        <div class="flex flex-wrap -mx-4">
          <div class="w-full lg:w-1/3 px-4 mb-6 lg:mb-0">
            <div class="h-80 lg:h-40">
              <StaticImage
                class="w-full h-full object-cover object-top rounded-xl"
                alt=""
                src={"../posts/picnic.jpeg"}
              />
              {/*<GatsbyImage image={image} alt={""} />*/}
              {/*<StaticImage
                class="w-full h-full object-cover object-top rounded-xl"
                alt=""
                src={staticImgSrc}
              />*/}
            </div>
          </div>
          <div class="w-full lg:w-2/3 px-4">
            <span class="inline-block mb-4 text-xs text-gray-500">{`${author} – ${date}`}</span>
            <h2 class="mb-4 text-3xl font-semibold font-heading">{title}</h2>
            <p class="mb-4 text-gray-500 leading-relaxed">{excerpt}</p>
            <p class="text-lg font-medium text-red-500 underline hover:no-underline">
              Läs inlägg
            </p>
          </div>
        </div>
      </Link>
    </div>
  )
}

const BlogPage = () => {
  const data = useStaticQuery(graphql`
    query {
      allMarkdownRemark {
        edges {
          node {
            frontmatter {
              title
              date
              author
              cover {
                childImageSharp {
                  fixed {
                    src
                  }
                }
              }
            }
            html
            excerpt
            fields {
              slug
            }
          }
        }
      }
    }
  `)
  const posts = data.allMarkdownRemark.edges.map(({ node }) => node)

  return (
    <PageLayout pageTitle={"Blog"}>
      <section class="relative py-20">
        <div class="container px-4 mx-auto">
          <div class="max-w-2xl mx-auto mb-20 text-center">
            <span class="text-xs text-blue-400 font-semibold">
              Följ våra äventyr
            </span>
            <h2 class="mt-8 mb-10 text-4xl font-semibold font-heading">
              Drone Bone Blog
            </h2>
            <p class="text-xl text-gray-500">
              Med jämna mellanrum delar vi med oss vad som är på gång. Det kan
              handla om intressanta kunder vi har träffat, om framsteg i vår
              teknikutveckling eller bara allmänt om drönarvärlden.
            </p>
          </div>
          <div class="flex flex-wrap -mx-4 -mb-16">
            {posts.map(
              (
                {
                  frontmatter: { title, date, author, cover },
                  html,
                  excerpt,
                  fields: { slug },
                },
                i
              ) => {
                return (
                  <BlogPostCard
                    date={date}
                    title={title}
                    author={author}
                    excerpt={excerpt}
                    slug={slug}
                    cover={cover.childImageSharp.fixed.src}
                    key={i}
                  />
                )
              }
            )}
          </div>
        </div>
      </section>
    </PageLayout>
  )
}

export default BlogPage
